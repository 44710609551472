import { EnumUtil } from '@csp/csp-common-enum-util';
import { ZonedDateTimeStr } from '@csp/csp-common-model';
import { AssessmentOutcomeV1 } from '@csp/dmdp-api-assessment-dto';
import { AssessmentAlgorithm } from './AssessmentAlgorithm';
import { AssessmentOutcomeKey } from './AssessmentOutcomeKey';
import { AssessmentOutcomeRef } from './AssessmentOutcomeRef';

export type AssessmentOutcome = {
  algorithm?: AssessmentAlgorithm;
  createdAt?: ZonedDateTimeStr;
  key: AssessmentOutcomeKey;
  refs: AssessmentOutcomeRef[];
  value?: string;
};

const from = ({ algorithm, value, refs, audit, key }: AssessmentOutcomeV1): AssessmentOutcome => ({
  algorithm: AssessmentAlgorithm.from(algorithm),
  createdAt: audit?.createdAt,
  key: EnumUtil.fromStringOrError<AssessmentOutcomeKey>(key, AssessmentOutcomeKey),
  refs: refs?.map(AssessmentOutcomeRef.from) ?? [],
  value,
});

export const AssessmentOutcome = {
  from,
};
