import { UserId } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import {
  AssessmentCriteriaFieldV1,
  AssessmentQueryCriterionV1,
  AssessmentQueryOperatorV1,
  AssessmentQuerySortOrderV1,
  AssessmentQuerySortV1,
  AssessmentQueryTypeV1,
  AssessmentSortFieldV1,
} from '@csp/dmdp-api-assessment-dto';
import { AssessmentCustomStatusType } from '../model/AssessmentCustomStatusType';
import { AssessmentCustomStatusValue } from '../model/AssessmentCustomStatusValue';
import { AssessmentOutcomeKey } from '../model/AssessmentOutcomeKey';
import { AssessmentAlgorithmResult } from '../model/AssessmentAlgorithmResult';
import { AssessmentRefKey } from '../model/AssessmentRefKey';
import { RPM_ASSESSMENT_TYPES, RpmAssessmentType } from '../model/RpmAssessmentType';

/**
 * Will sort {@link AssessmentCustomStatusValue.NOT_VIEWED} status first and then
 * {@link AssessmentCustomStatusValue.VIEWED} status.
 */
const notViewedCustomStatusAscSortQuery = (): AssessmentQuerySortV1 => ({
  field: AssessmentSortFieldV1.CUSTOM_STATUS_VALUE,
  order: AssessmentQuerySortOrderV1.ASC,
  subField: AssessmentSortFieldV1.CUSTOM_STATUS_VALUE,
});

const latestCreatedAtSortQuery = (): AssessmentQuerySortV1 => ({
  field: AssessmentSortFieldV1.CREATED_AT,
  order: AssessmentQuerySortOrderV1.DESC,
  subField: AssessmentSortFieldV1.CREATED_AT,
});

const latestCreatedAtAndNotViewedStatusFirstSortQuery = (): AssessmentQuerySortV1[] => [
  notViewedCustomStatusAscSortQuery(),
  latestCreatedAtSortQuery(),
];

const assessmentTypesQuery = (...assessmentTypes: readonly RpmAssessmentType[]): AssessmentQueryCriterionV1 => ({
  fieldCriterion: {
    field: AssessmentCriteriaFieldV1.ASSESSMENT_TYPE,
    type: AssessmentQueryTypeV1.IN,
    values: [...assessmentTypes],
  },
});

const rpmAssessmentTypesQuery = (): AssessmentQueryCriterionV1 => assessmentTypesQuery(...RPM_ASSESSMENT_TYPES);

const algorithmCodesQuery = (algorithmCodes: readonly RpmAlgorithmCode[]): AssessmentQueryCriterionV1 => ({
  fieldCriterion: {
    field: AssessmentCriteriaFieldV1.ALGORITHM_CODE,
    type: AssessmentQueryTypeV1.IN,
    values: [...algorithmCodes],
  },
});

const algorithmResultNotOkQuery = (): AssessmentQueryCriterionV1 => ({
  operator: AssessmentQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.OUTCOME_KEY,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentOutcomeKey.algorithmResult],
      },
    },
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.OUTCOME_VALUE,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentAlgorithmResult.ALGORITHM_RESULT_NOT_OK],
      },
    },
  ],
});

const statusViewedQuery = (): AssessmentQueryCriterionV1 => ({
  operator: AssessmentQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.CUSTOM_STATUS_TYPE,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentCustomStatusType.VIEW_STATUS],
      },
    },
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.CUSTOM_STATUS_VALUE,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentCustomStatusValue.VIEWED],
      },
    },
  ],
});

const statusNotViewedQuery = (): AssessmentQueryCriterionV1 => ({
  operator: AssessmentQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.CUSTOM_STATUS_TYPE,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentCustomStatusType.VIEW_STATUS],
      },
    },
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.CUSTOM_STATUS_VALUE,
        type: AssessmentQueryTypeV1.EQ,
        values: [AssessmentCustomStatusValue.NOT_VIEWED],
      },
    },
  ],
});

const noViewedStatusQuery = (): AssessmentQueryCriterionV1 => ({
  operator: AssessmentQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.CUSTOM_STATUS_VALUE,
        type: AssessmentQueryTypeV1.NOT_EQ,
        values: [AssessmentCustomStatusValue.VIEWED],
      },
    },
  ],
});

const refIdsQuery = (refKey: AssessmentRefKey, ids: readonly string[]): AssessmentQueryCriterionV1 => ({
  operator: AssessmentQueryOperatorV1.AND,
  criteria: [
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.REFS_KEY,
        type: AssessmentQueryTypeV1.EQ,
        values: [refKey],
      },
    },
    {
      fieldCriterion: {
        field: AssessmentCriteriaFieldV1.REFS_VALUE,
        type: AssessmentQueryTypeV1.IN,
        values: [...ids],
      },
    },
  ],
});

const userIdsQuery = (...userIds: readonly UserId[]): AssessmentQueryCriterionV1 => ({
  fieldCriterion: {
    field: AssessmentCriteriaFieldV1.USER_ID,
    type: AssessmentQueryTypeV1.IN,
    values: [...userIds],
  },
});

const maxEventIdQuery = (maxEventId: string): AssessmentQueryCriterionV1 => ({
  fieldCriterion: {
    field: AssessmentCriteriaFieldV1.EVENT_ID,
    type: AssessmentQueryTypeV1.GREATER_THAN,
    values: [maxEventId],
  },
});

export const RpmAssessmentQuery = {
  algorithmCodesQuery,
  algorithmResultNotOkQuery,
  assessmentTypesQuery,
  latestCreatedAtAndNotViewedStatusFirstSortQuery,
  latestCreatedAtSortQuery,
  maxEventIdQuery,
  refIdsQuery,
  rpmAssessmentTypesQuery,
  statusNotViewedQuery,
  statusViewedQuery,
  noViewedStatusQuery,
  userIdsQuery,
};
