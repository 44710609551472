import { nowToZonedDateTimeCurrentZone, zonedSubtractDuration } from '@csp/csp-common-date-util';
import { Maybe, MetricPeriod, MetricPeriodUnitType } from '@csp/csp-common-model';
import { QuestionnaireMetricPeriodUnitType } from '@csp/csp-common-questionnaire';
import { ScheduleRequestMetricInfo } from '../model/ScheduleRequestMetricInfo';

const toDays = ({ amount, unit }: MetricPeriod, daysPerMonth: number): number => {
  const day = amount;
  switch (unit) {
    case MetricPeriodUnitType.DAY:
      return day;
    case MetricPeriodUnitType.WEEK:
      return day * 7;
    case MetricPeriodUnitType.MONTH:
      return day * daysPerMonth;
    case MetricPeriodUnitType.YEAR:
      return day * 365;
    default:
      throw new Error(`Non supported period unit: ${unit}`);
  }
};

const periodToMillis = (metricPeriod: MetricPeriod, daysPerMonth: number): number => {
  const now = nowToZonedDateTimeCurrentZone();
  const inPast = zonedSubtractDuration(now, { days: toDays(metricPeriod, daysPerMonth) });
  return now.unixTimeMillis - inPast.unixTimeMillis;
};

const questionnairePeriodToMetricPeriod = (
  questionnaireMetricUnit: Maybe<QuestionnaireMetricPeriodUnitType>,
): Maybe<MetricPeriodUnitType> => {
  switch (questionnaireMetricUnit) {
    case QuestionnaireMetricPeriodUnitType.YEAR:
      return MetricPeriodUnitType.YEAR;
    case QuestionnaireMetricPeriodUnitType.MONTH:
      return MetricPeriodUnitType.MONTH;
    case QuestionnaireMetricPeriodUnitType.WEEK:
      return MetricPeriodUnitType.WEEK;
    case QuestionnaireMetricPeriodUnitType.DAY:
      return MetricPeriodUnitType.DAY;
    default:
      throw new Error('Unsupported ');
  }
};

const isRecentMetricPeriodCompliant = (metricInfos: ScheduleRequestMetricInfo[]): boolean =>
  metricInfos.every(({ metricInfo }) => metricInfo.complianceMetric?.recentCompliance?.isCompliant ?? false);

export const MetricPeriodUtil = {
  periodToMillis,
  questionnairePeriodToMetricPeriod,
  isRecentMetricPeriodCompliant,
};
