import { RoleType } from '@csp/dmdp-api-common-dto';
import { ContentRole } from '../models/ContentRole';

const hcpContentRoles = [
  RoleType.CRA,
  RoleType.PI,
  RoleType.INVESTIGATOR,
  RoleType.STUDY_ASST,
  RoleType.READ_ALL,
  RoleType.SSTM,
  RoleType.GSTM,
  RoleType.SERVICE_CSA,
];

const isHcpContentRole = (roleTypes: RoleType[]): boolean =>
  hcpContentRoles.some(role => roleTypes.find(r => r) === role);

export const getContentRole = (roleTypes: RoleType[]): ContentRole => {
  if (roleTypes === undefined) {
    return undefined;
  } else if (isHcpContentRole(roleTypes)) {
    return 'hcp';
  } else if (roleTypes.find(role => role === 'PATIENT')) {
    return 'patient';
  }
  return undefined;
};
