import { fromTimezoneStr } from '@csp/csp-common-date-util';
import { EnumUtil } from '@csp/csp-common-enum-util';
import { EventIdStr, UserId, ZonedDateTime } from '@csp/csp-common-model';
import { RpmAlgorithmCode } from '@csp/csp-common-rpm-model';
import { AssessmentAggregateGroupResultV1 } from '@csp/dmdp-api-assessment-dto';
import { AssessmentCustomStatusValue } from './AssessmentCustomStatusValue';

export type AssessmentAggregateGroup = {
  algorithmCode?: RpmAlgorithmCode;
  count: number;
  isViewed: boolean;
  latestCreatedAt?: ZonedDateTime;
  maxEventId?: EventIdStr;
  userId?: UserId;
};

const from = ({
  count,
  fields,
  maxAssessmentTimestamp,
  maxEventId,
}: AssessmentAggregateGroupResultV1): AssessmentAggregateGroup => ({
  algorithmCode: EnumUtil.fromMaybeString<RpmAlgorithmCode>(fields.assessmentAlgorithmCode, RpmAlgorithmCode),
  count: count ?? 0,
  isViewed: fields.customStatusValue === AssessmentCustomStatusValue.VIEWED,
  latestCreatedAt: maxAssessmentTimestamp ? fromTimezoneStr(maxAssessmentTimestamp) : undefined,
  maxEventId,
  userId: fields.userId,
});

export const AssessmentAggregateGroup = {
  from,
};
